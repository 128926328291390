<header>
    <div class="jumbotron">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center" style="font-size: 50px">Shaban Enterprises</h1>
            <p class="text-center" style="font-size: 20px">Since 1967, the Shaban Family has built a portfolio of companies  in Greater Fort Worth while promoting ethical business practices that reflect the family's values.</p>
          </div>
        </div>
      </div>
    </div>
  </header>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-4 mt-2 text-center">
          <h2>OUR COMPANIES</h2>
        </div>
      </div>
    </div>
    <div class="container ">
      <div class="card-deck">
        <div class="col-12 col-md-6 col-lg-6"> 
            <div class="card  h-100 grow" style="text-align:center; padding: 1rem" >
                <a class ="link-unstyled" href='https://www.internationalcaravan.com'>
                    <div class=" d-flex justify-content-center align-items-center" style="height:325px">
                    <img class="card-img-top img-fluid" style="max-width:300px" alt="International Caravan Logo" src="assets/ICI_Logo.jpg">
                    </div>
                    <div class="card-body">
                        <h3>International Caravan, Inc.</h3>
                        <p>Home furnishings importer, wholesaler, and drop-shipper featuring a wide-ranging outdoor and indoor furniture and home-decor catalog available nationwide on the web's top e-commerce platforms.  Opened in 1967, this is the first and the largest Shaban Family business.</p>
                         </div>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <div class="card  h-100 grow" style="text-align:center; padding: 1rem" >
                <a class ="link-unstyled" href='https://www.blazingneedleslp.com'>
                    <div class="d-flex justify-content-center align-items-center" style="height:325px">
                    <img class="card-img-top img-fluid" style="max-width:300px" alt="International Caravan Logo" src="assets/BN_Logo.png">
                    </div>
                    <div class="card-body">
                        <h3>Blazing Needles, L.P.</h3>
                        <p>Home textiles importer, wholesaler, manufacturer, and drop-shipper specializing in furniture cushions, decorative pillows, futon mattresses, and more, all available nationwide on the web's top e-commerce platforms.  Opened in the mid-1980s, Blazing Needles has fast grown to be one of the web's most visible independent home cushion brands.</p>
                      </div>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <div class="card  h-100 grow" style="text-align:center; padding: 1rem" >
                <a class ="link-unstyled" href='https://www.saffronsky.net'>
                    <div class=" d-flex justify-content-center align-items-center" style="height:325px">
                        <img class="card-img-top img-fluid " style="max-width:300px;" alt="Saffron Sky Logo" src="assets/SS_Logo.jpg" >
                    </div>
                    <div class="card-body">
                        <h3>Saffron Sky Mediterranean Bakery Cafe</h3>
                        <p>Casual, friendly mediterranean cafe in the heart of East Fort Worth specializing in traditional middle-eastern fare</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6"> 
            <div class="card  h-100 grow" style="text-align:center; padding: 1rem" >
                <a class ="link-unstyled" href=''>
                    <div class=" d-flex justify-content-center align-items-center" style="height:325px">
                        <img class="card-img-top img-fluid" style="max-width:300px;"  alt="Shaban Real Estate Logo" src="assets/SRE_Logo.jpg">
                    </div>
                    <div class="card-body">
                    <h3 class="card-title">Shaban Real Estate, LLC</h3>
                    <p class="card-text">Real estate holdings company with a portfolio of both commercial and residential real estate available for sale and for rent in the greater Fort Worth area.</p>
                    </div>
                </a>
            </div>
        </div>
      </div>
      </div>







  <footer class="text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>Copyright 2019 © Shaban Enterprises. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>